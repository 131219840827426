"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TAB_FORMS = exports.FORM_SELECTION_EVENT = void 0;
const TAB_FORMS = exports.TAB_FORMS = {
  FUNDING_STATUS: {
    UNKNOWN: {
      code: 0,
      value: 'UNKNOWN'
    },
    RECEIVED: {
      code: 1,
      value: 'RECEIVED'
    },
    BOOKED: {
      code: 2,
      value: 'BOOKED'
    },
    REJECTED: {
      code: 3,
      value: 'REJECTED'
    },
    HELD: {
      code: 4,
      value: 'HELD'
    },
    SENT: {
      code: 5,
      value: 'SENT'
    },
    FAILED: {
      code: 6,
      value: 'FAILED'
    }
  },
  E_DOCS: {
    LEASE_AGREEMENT: {
      code: '00501',
      isRequired: true
    },
    CREDIT_APPLICATION: {
      code: '00503',
      isRequired: true
    },
    OTHER: {
      code: '00507',
      isRequired: false
    }
  }
};
const FORM_SELECTION_EVENT = exports.FORM_SELECTION_EVENT = 'crm-desking-desking/form-tables/rows-selected';