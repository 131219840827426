"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _effects = require("redux-saga/effects");
var _deskingCommons = require("@sm360/desking-commons");
var _actions = require("../actions");
var _getItems = _interopRequireDefault(require("./items/getItems"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function* _default() {
  const specialPrograms = yield (0, _effects.call)(_getItems.default, [_deskingCommons.ITEM_CATEGORY.REBATE]);
  if (specialPrograms) {
    yield (0, _effects.put)(_actions.Creators.setCreditApplicationSpecialPrograms(specialPrograms));
  }
}