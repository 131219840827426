"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStaticData = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsApi = require("@sm360/commons-api");
const getStaticData = async _ref => {
  let {
    initialState,
    dispatch
  } = _ref;
  const {
    orgId,
    orgUnitId,
    userId
  } = initialState || {};
  dispatch({
    type: 'uiStartLoading',
    name: 'LOAD_TRADE_IN'
  });

  // user rights verification
  let EvaluateVehicle = false;
  try {
    const response = await _appsCore.axios.get(`${process.env.REACT_APP_AUTHORIZATION_URL}/users/${userId}/hasRight?right=AppraiseTradeInValue`);
    if (response?.data?.response) EvaluateVehicle = response.data.response;
  } catch {}

  // dealer rights verification
  let isShowroomV2 = false;
  let isShowroomV25 = false;
  let isDesking = false;
  try {
    const orgUnits = await _commonsApi.OrganizationService.getDealers({
      orgId
    });
    const orgUnitConfig = orgUnits?.find(orgUnit => orgUnit.id === orgUnitId)?.organizationUnitConfiguration;
    if (orgUnitConfig) {
      isShowroomV2 = !!orgUnitConfig?.showroomv2;
      isShowroomV25 = !!orgUnitConfig?.showroomv25;
      isDesking = !!orgUnitConfig?.desking;
    }
  } catch {}
  const state = {
    ...initialState,
    access: {
      EvaluateVehicle,
      isShowroomV2,
      isShowroomV25,
      isDesking
    }
  };
  dispatch({
    type: 'init',
    initialState: state
  });
};
exports.getStaticData = getStaticData;