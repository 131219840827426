"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStatusCode = exports.generateFinancePlansChoices = exports.findPlanFromOptions = void 0;
var _financial = require("../constants/financial");
const {
  PENDING,
  APPROVED,
  DECLINED,
  CONDITIONED,
  CANCELLED,
  WITHDRAWN,
  ERROR,
  PASSED,
  FAILED,
  EXPIRED_EN,
  EXPIRED_FR
} = _financial.DECISION_STATUS;

/**
 * @param {Array} plans
 * @param {Object} options
 * @returns {Object|undefined}
 * @throws TypeError
 */
const findPlanFromOptions = (plans, options) => {
  if (!Array.isArray(plans) || plans.length === 0) {
    return {};
  }
  const {
    financePlan,
    purchaseMethod
  } = options;
  if (typeof financePlan !== 'string' || typeof purchaseMethod !== 'string') {
    throw new TypeError(`A financeOptions object is not valid:
            financePlan=${typeof financePlan} purchaseMedthod=${typeof purchaseMethod}`);
  }
  return plans.find(item => item.purchaseMethod === options.purchaseMethod && item.financePlan === options.financePlan);
};

/**
 * @param {Array} plans
 * @returns {Array}
 * @throws TypeError
 */
exports.findPlanFromOptions = findPlanFromOptions;
const generateFinancePlansChoices = plans => {
  if (!Array.isArray(plans) || plans.length === 0) {
    return [];
  }
  const options = plans.map(plan => ({
    isDisabled: !plan.isAvailable,
    value: `${plan.financePlan}|${plan.purchaseMethod}`,
    label: plan.financePlanDescription
  }));

  // If all plans are disabled, don't return any options
  const areAllDisabled = options.every(opt => opt.isDisabled);
  return areAllDisabled ? [] : options;
};

/**
 * @param {String} status
 * @returns {Number}
 */
exports.generateFinancePlansChoices = generateFinancePlansChoices;
const getStatusCode = status => {
  switch (status) {
    case APPROVED:
    case PASSED:
      return 2;
    case DECLINED:
    case CANCELLED:
    case ERROR:
    case FAILED:
      return 3;
    case EXPIRED_EN:
    case EXPIRED_FR:
    case CONDITIONED:
    case WITHDRAWN:
      return 4;
    case PENDING:
    default:
      return 1;
  }
};
exports.getStatusCode = getStatusCode;