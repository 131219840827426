"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTypes = exports.getStaticData = exports.getSourcesByDealer = exports.getMedias = exports.getEmployees = exports.getDealers = exports.fetchProvince = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
var _commonsApi = require("@sm360/commons-api");
var _constants = require("../tools/constants");
const XMS_ENDPOINT = process.env.REACT_APP_XMS_API_URL;
const NEW_CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;
const getData = query => query?.data || [];

// Get leads types (for a specific dealer if specified)
const getTypes = async _ref => {
  let {
    key,
    dispatch,
    organizationUnitId,
    lang
  } = _ref;
  const results = await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/dealer-configurations/leads/types${organizationUnitId ? `?carDealerId=${organizationUnitId}` : ''}`);
  dispatch({
    type: 'setStaticData',
    staticData: [key, results.data.map(t => ({
      id: t.id,
      label: t.type,
      value: String(t.id)
    })).sort((a, b) => a.label.localeCompare(b.label))]
  });
};

// Get leads sources for a specific dealer
exports.getTypes = getTypes;
const getSourcesByDealer = async _ref2 => {
  let {
    key,
    dispatch,
    organizationUnitId,
    t
  } = _ref2;
  const results = await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/dealer-configurations/leads/sources?carDealerId=${organizationUnitId}`);
  const suggestedSourceTypes = results.data.filter(s => s.suggestion).map(s => ({
    id: s.id,
    label: s.sourceType,
    value: String(s.id)
  }));
  const otherSourceTypes = results.data.filter(s => !s.suggestion).map(s => ({
    id: s.id,
    label: s.sourceType,
    value: String(s.id)
  }));
  dispatch({
    type: 'setStaticData',
    staticData: [key, [{
      label: t('leads.createView.createLead.createForm.SUGGESTIONS'),
      options: suggestedSourceTypes
    }, {
      label: t('leads.createView.createLead.createForm.OTHER_SOURCES'),
      options: otherSourceTypes
    }]]
  });
};

// Get leads medias (for a specific dealer if specified)
exports.getSourcesByDealer = getSourcesByDealer;
const getMedias = async _ref3 => {
  let {
    key,
    dispatch,
    organizationId,
    organizationUnitId
  } = _ref3;
  const results = await _appsCore.axios.get(`${NEW_CRM_ENDPOINT}/dealer-configurations/leads/medias?carDealerGroupId=${organizationId}${organizationUnitId ? `&carDealerId=${organizationUnitId}` : ''}`);
  // const results = await axios.get(
  //     `${NEW_CRM_ENDPOINT}/dealer-configurations/leads/medias?carDealerId=${organizationUnitId}`);
  dispatch({
    type: 'setStaticData',
    staticData: [key, results.data.map(t => ({
      id: t.id,
      label: t.media,
      value: String(t.id)
    })).sort((a, b) => a.label.localeCompare(b.label))]
  });
};

// Get employees
exports.getMedias = getMedias;
const getEmployees = async _ref4 => {
  let {
    key,
    dispatch,
    right,
    body,
    translate
  } = _ref4;
  const results = await _appsCore.axios.post(`${NEW_CRM_ENDPOINT}/users?activeOnly=true&withOrganizationUnitRight=${right}`, body);
  let additionalOptions = [];
  switch (key) {
    case 'userId':
    case 'restricted_userId':
      additionalOptions = [_constants.SYSTEM_WEB_USER];
      break;
    default:
      break;
  }
  dispatch({
    type: 'setStaticData',
    staticData: [key, [...additionalOptions, ...results.data.map(t => ({
      id: t.id,
      label: (0, _commonsUtils.getUserLabel)(t, translate),
      value: t.id,
      email: t.email
    })).sort((a, b) => (0, _commonsUtils.compareUserLabel)(a, b, translate))]]
  });
};

// Get dealers/organizationUnitId
exports.getEmployees = getEmployees;
const getDealers = async _ref5 => {
  let {
    id,
    dispatch
  } = _ref5;
  const orgUnits = await _commonsApi.OrganizationService.getDealers({
    orgId: id
  });
  const units = orgUnits.map(unitToMap => ({
    key: unitToMap.id,
    value: unitToMap.id,
    id: unitToMap.id,
    label: unitToMap.name
  }));
  dispatch({
    type: 'setStaticData',
    staticData: ['organizationUnitId', units]
  });
  return units;
};

// Get provinces
exports.getDealers = getDealers;
const fetchProvince = async _ref6 => {
  let {
    dispatch
  } = _ref6;
  const territories = await _appsCore.axios.get(`${XMS_ENDPOINT}/staticData/provinces`);
  const provinces = territories.data.filter(t => t.countryCode === 'CA');
  const states = territories.data.filter(t => t.countryCode === 'US');
  dispatch({
    type: 'setStaticData',
    staticData: ['territories', {
      CA: provinces,
      US: states
    }]
  });
  return territories;
};
exports.fetchProvince = fetchProvince;
const translationPrefix = 'leads.updateView.financeForm';
const getYearArray = () => {
  const years = [];
  let currentYear = new Date().getFullYear();
  const endYear = currentYear - 70;
  while (currentYear >= endYear) {
    const newYear = currentYear--;
    years.push({
      id: newYear,
      value: newYear,
      label: newYear
    });
  }
  return years;
};
const getMonthArray = t => [{
  id: 0,
  value: 0,
  label: t('commons.months.JANUARY')
}, {
  id: 1,
  value: 1,
  label: t('commons.months.FEBRUARY')
}, {
  id: 2,
  value: 2,
  label: t('commons.months.MARCH')
}, {
  id: 3,
  value: 3,
  label: t('commons.months.APRIL')
}, {
  id: 4,
  value: 4,
  label: t('commons.months.MAY')
}, {
  id: 5,
  value: 5,
  label: t('commons.months.JUNE')
}, {
  id: 6,
  value: 6,
  label: t('commons.months.JULY')
}, {
  id: 7,
  value: 7,
  label: t('commons.months.AUGUST')
}, {
  id: 8,
  value: 8,
  label: t('commons.months.SEPTEMBER')
}, {
  id: 9,
  value: 9,
  label: t('commons.months.OCTOBER')
}, {
  id: 10,
  value: 10,
  label: t('commons.months.NOVEMBER')
}, {
  id: 11,
  value: 11,
  label: t('commons.months.DECEMBER')
}];
const yearArray = getYearArray();
const getStaticData = async _ref7 => {
  let {
    dispatch,
    organization,
    oidcUser,
    t,
    rights,
    queries
  } = _ref7;
  const monthsArray = getMonthArray(t);
  const orgUnits = getData(queries.dealers);
  const orgUnitsIds = orgUnits.map(orgUnit => orgUnit.id);
  const provinces = getData(queries.provinces).filter(t => t.countryCode === 'CA');
  const states = getData(queries.provinces).filter(t => t.countryCode === 'US');
  const leadTypes = getData(queries.leadTypes);
  const leadGroupedTypes = getData(queries.leadGroupedTypes);
  const leadStatuses = getData(queries.leadStatuses);
  const leadAvailableStatuses = getData(queries.leadAvailableStatuses);
  const leadSources = getData(queries.leadSources);
  const leadMedias = getData(queries.leadMedias);
  const ownerIds = getData(queries.ownerIds);
  const salespersonIds = getData(queries.salespersonIds);
  const salesManagerIds = getData(queries.salesManagerIds);
  const businessManagerId = getData(queries.businessManagerId);
  const urlParams = new URLSearchParams(window.location.search);
  const hasParams = urlParams.toString().length > 0;
  const getUrlParam = (key, isNumber, isRange, isBoolean) => {
    let urlValue = urlParams.has(key) ? urlParams.get(key).split(',') : null;
    if (urlValue) {
      if (isNumber) urlValue = urlValue.map(k => Number(k));
      if (isRange) {
        if (urlValue.length === 1) urlValue = {
          rangeId: urlValue[0]
        };
        if (urlValue.length === 2) urlValue = {
          min: Number(urlValue[0]),
          max: Number(urlValue[1])
        };
      }
      if (isBoolean) urlValue = !!urlValue[0];
    }
    return urlValue;
  };
  const LS = JSON.parse(localStorage.getItem(`${oidcUser.profile.sub}@${organization.id}`));
  const LSFilters = LS?.[`crm-leads-filters@${organization.id}`];
  const userCanSeeUnassignedLeads = rights.includes('ReadUnassignedLead');
  const initialFilters = {
    displayArchived: false,
    displayWithQuote: false,
    organizationId: organization.id,
    organizationUnitIds: orgUnitsIds,
    leadsDateRange: {},
    leadTypeIds: leadTypes.map(t => t.id),
    leadStatusIds: leadAvailableStatuses.map(t => t.id),
    leadSourceType: leadSources.map(t => t.id),
    salespersonIds: [-1, ...salespersonIds.map(t => t.id)],
    salesManagerIds: [-1, ...salesManagerIds.map(t => t.id)],
    userId: ownerIds.map(t => t.id),
    searchTerms: ''
  };
  let filters = hasParams ? {
    // Bypass the localStorage when params exists and fallback on full selection
    displayArchived: initialFilters.displayArchived,
    displayWithQuote: getUrlParam('displayWithQuote', false, false, true) || initialFilters.displayWithQuote,
    organizationId: initialFilters.organizationId,
    organizationUnitIds: initialFilters.organizationUnitIds,
    leadsDateRange: getUrlParam('leadsDateRange', false, true) || initialFilters.leadsDateRange,
    leadTypeIds: getUrlParam('leadType', true) || initialFilters.leadTypeIds,
    leadStatusIds: initialFilters.leadStatusIds,
    leadSourceType: getUrlParam('leadSourceType', true) || initialFilters.leadSourceType,
    salespersonIds: initialFilters.salespersonIds,
    salesManagerIds: initialFilters.salesManagerIds,
    userId: initialFilters.userId,
    searchTerms: initialFilters.searchTerms
  } : {
    displayArchived: LSFilters?.displayArchived || initialFilters.displayArchived,
    displayWithQuote: LSFilters?.displayWithQuote || initialFilters.displayWithQuote,
    organizationId: initialFilters.organizationId,
    organizationUnitIds: LSFilters?.dealers || initialFilters.organizationUnitIds,
    leadsDateRange: LSFilters?.leadsDateRange || initialFilters.leadsDateRange,
    leadTypeIds: LSFilters?.leadType || initialFilters.leadTypeIds,
    leadStatusIds: LSFilters?.leadStatus || initialFilters.leadStatusIds,
    leadSourceType: LSFilters?.leadSourceType || initialFilters.leadSourceType,
    salespersonIds: LSFilters?.salespersons || initialFilters.salespersonIds,
    salesManagerIds: LSFilters?.salesManagers || initialFilters.salesManagerIds,
    userId: LSFilters?.users || initialFilters.userId,
    searchTerms: typeof LSFilters?.searchTerms === 'string' ? LSFilters?.searchTerms : initialFilters.searchTerms
  };
  dispatch({
    type: 'initStaticData',
    staticData: {
      organizationUnitId: orgUnits,
      territories: {
        CA: provinces,
        US: states
      },
      leadTypeIds: leadTypes,
      leadGroupedTypeIds: leadGroupedTypes,
      leadAvailableStatusIds: leadAvailableStatuses,
      leadStatusIds: leadStatuses,
      leadSourceType: leadSources,
      leadMediaId: leadMedias,
      leadServiceVehicle: {
        year: yearArray
      },
      userId: ownerIds,
      salespersonIds: [...(userCanSeeUnassignedLeads ? [{
        label: t('leads.filter.label.noLabelAssigned', {
          placeholder: t('leads.filter.label.salesRep')
        }),
        id: -1,
        value: -1
      }] : []), ...salespersonIds],
      salesManagerIds: [...(userCanSeeUnassignedLeads ? [{
        label: t('leads.filter.label.noLabelAssigned', {
          placeholder: t('leads.filter.label.salesDir')
        }),
        id: -1,
        value: -1
      }] : []), ...salesManagerIds],
      businessManagerId,
      contact: {
        preferredContactMoment: [{
          label: t('leads.updateView.contactForm.contactInformation.preferredContactMoment.WEEKDAY'),
          value: 'WEEKDAY',
          id: 'WEEKDAY'
        }, {
          label: t('leads.updateView.contactForm.contactInformation.preferredContactMoment.WEEKEND'),
          value: 'WEEKEND',
          id: 'WEEKEND'
        }, {
          label: t('leads.updateView.contactForm.contactInformation.preferredContactMoment.ANY'),
          value: 'ANY',
          id: 'ANY'
        }],
        preferredContactTime: [{
          label: t(`leads.updateView.contactForm.contactInformation.preferredContactTime.MORNING`),
          value: 'MORNING',
          id: 'MORNING'
        }, {
          label: t(`leads.updateView.contactForm.contactInformation.preferredContactTime.AFTERNOON`),
          value: 'AFTERNOON',
          id: 'AFTERNOON'
        }, {
          label: t(`leads.updateView.contactForm.contactInformation.preferredContactTime.EVENING`),
          value: 'EVENING',
          id: 'EVENING'
        }, {
          label: t(`leads.updateView.contactForm.contactInformation.preferredContactTime.ANY`),
          value: 'ANY',
          id: 'ANY'
        }],
        country: [{
          label: t(`leads.updateView.contactForm.localization.country.CANADA`),
          value: 'CA',
          id: 'CA'
        }, {
          label: t(`leads.updateView.contactForm.localization.country.USA`),
          value: 'US',
          id: 'US'
        }],
        contactFinancing: {
          jobStartYear: yearArray,
          jobStartMonth: monthsArray,
          homeStartYear: yearArray,
          homeStartMonth: monthsArray,
          jobType: [{
            id: 1,
            label: t(`${translationPrefix}.jobType.JOB_INSURANCE`)
          }, {
            id: 2,
            label: t(`${translationPrefix}.jobType.SOCIAL_INSURANCE`)
          }, {
            id: 3,
            label: t(`${translationPrefix}.jobType.HOME`)
          }, {
            id: 4,
            label: t(`${translationPrefix}.jobType.STUDENT`)
          }, {
            id: 5,
            label: t(`${translationPrefix}.jobType.RETIRED`)
          }, {
            id: 6,
            label: t(`${translationPrefix}.jobType.FULL_TIME`)
          }, {
            id: 7,
            label: t(`${translationPrefix}.jobType.PART_TIME`)
          }, {
            id: 8,
            label: t(`${translationPrefix}.jobType.CONTRACT`)
          }, {
            id: 9,
            label: t(`${translationPrefix}.jobType.TEMPORARY_INVALID`)
          }, {
            id: 10,
            label: t(`${translationPrefix}.jobType.PERMANENTLY_INVALID`)
          }, {
            id: 11,
            label: t(`${translationPrefix}.jobType.PARENTAL_BREAK`)
          }, {
            id: 12,
            label: t(`${translationPrefix}.jobType.SELF_EMPLOYED`)
          }, {
            id: 13,
            label: t(`${translationPrefix}.jobType.OTHER`)
          }],
          rentType: [{
            id: 1,
            label: t(`${translationPrefix}.rentType.TENANT`)
          }, {
            id: 2,
            label: t(`${translationPrefix}.rentType.LANDLORD`)
          }, {
            id: 3,
            label: t(`${translationPrefix}.rentType.UNKNOWN`)
          }],
          approvalStatus: [{
            id: 1,
            label: t(`${translationPrefix}.approvalStatus.IN_TREATMENT`)
          }, {
            id: 2,
            label: t(`${translationPrefix}.approvalStatus.PRE_APPROBATION`)
          }, {
            id: 3,
            label: t(`${translationPrefix}.approvalStatus.CONDITIONAL_APPROBATION`)
          }, {
            id: 4,
            label: t(`${translationPrefix}.approvalStatus.REFUSED`)
          }]
        }
      }
    },
    query: {
      filters
    }
  });
};
exports.getStaticData = getStaticData;