"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDealVehiclesByContactId = exports.getDealTypes = exports.getDealStatuses = exports.getDealPaymentTypes = exports.getDealFinancialInstitutions = exports.deleteDeal = exports.createDealFromQuote = void 0;
var _request = require("../../tools/request");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;
const DEAL_API_URL = process.env.REACT_APP_DEAL_API_URL;

/**
 * @returns {Promise}
 */
const getDealTypes = () => {
  return (0, _request.get)(`${CRM_API_URL}/deals/static/types`, null, null, 'getDealTypes');
};

/**
 * @returns {Promise}
 */
exports.getDealTypes = getDealTypes;
const getDealStatuses = () => {
  return (0, _request.get)(`${CRM_API_URL}/deals/static/statuses`, null, null, 'getDealStatuses');
};

/**
 * @param {number} organizationId
 * @param {Array} organizationUnitIds
 * @returns {Promise}
 */
exports.getDealStatuses = getDealStatuses;
const getDealFinancialInstitutions = function (organizationId) {
  let organizationUnitIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return (0, _request.post)(`${CRM_API_URL}/deals/financialInstitutions`, {
    filters: {
      organizationId,
      organizationUnitIds
    },
    meta: {
      from: 0,
      size: 100
    }
  }, null, null, 'getDealFinancialInstitutions');
};

/**
 * @returns {Promise}
 */
exports.getDealFinancialInstitutions = getDealFinancialInstitutions;
const getDealPaymentTypes = () => {
  return (0, _request.get)(`${CRM_API_URL}/deals/static/paymentTypes`, null, null, 'getDealPaymentTypes');
};

/**
 * @param {Object} body
 * @returns {Promise}
 */
exports.getDealPaymentTypes = getDealPaymentTypes;
const createDealFromQuote = body => {
  const url = `${DEAL_API_URL}/deals/createDealFromQuote`;
  return (0, _request.post)(url, body);
};

/**
 * @param {String} quoteId
 * @param {String} orgUnitId
 * @returns {Promise}
 */
exports.createDealFromQuote = createDealFromQuote;
const deleteDeal = (quoteId, orgUnitId) => {
  const url = `${DEAL_API_URL}/deals/deleteByQuoteId/${quoteId}?organizationUnitId=${orgUnitId}`;
  return (0, _request.post)(url);
};

/**
 * @param {String} contactId
 * @returns {Promise}
 */
exports.deleteDeal = deleteDeal;
const getDealVehiclesByContactId = contactId => {
  return (0, _request.get)(`${CRM_API_URL}/vehicles/dms?contactId=${contactId}`, null, null, 'getDealVehiclesByContactId');
};
exports.getDealVehiclesByContactId = getDealVehiclesByContactId;