"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadDocuments = exports.uploadContactsFile = exports.updateVehicleDetails = exports.updateDocumentDesc = exports.updateContact = exports.optOutContact = exports.invalidateContactDuplicates = exports.getVehicleDetails = exports.getDownloadTemplate = exports.getDocument = exports.getContactsList = exports.getContactsDuplicates = exports.getContactSubscriptions = exports.getContactLeads = exports.getContactDocuments = exports.getContact = exports.downloadDocument = exports.deleteContact = exports.createContactTag = exports.createContact = exports.archiveDocument = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsUtils = require("@sm360/commons-utils");
var _payload = require("../config/payload.transform");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// Get contacts list
const getContactsList = async _ref => {
  let {
    body,
    pageSize,
    pageIndex,
    request,
    sortBy
  } = _ref;
  const newQuery = {
    filters: {
      ...body.filters,
      searchTerms: request ? (0, _commonsUtils.sanitizeSearch)(request).split(' ') : []
    },
    meta: {
      from: pageIndex,
      size: pageSize,
      sort: sortBy //[{id: 'lastModifiedDate', desc: true}],
    }
  };
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/contacts/search`, newQuery);
};
exports.getContactsList = getContactsList;
const getContact = async id => {
  const contact = await _appsCore.axios.get(`${CRM_ENDPOINT}/contacts/${id}`);
  return Promise.resolve((0, _payload.transformContactFromBackend)(contact.data));
};
exports.getContact = getContact;
const updateContact = async (id, body) => {
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/contacts/${id}`, body);
};
exports.updateContact = updateContact;
const deleteContact = async id => {
  return await _appsCore.axios.delete(`${CRM_ENDPOINT}/contacts/${id}`);
};
exports.deleteContact = deleteContact;
const createContact = async body => {
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/contacts`, body);
};
exports.createContact = createContact;
const optOutContact = async _ref2 => {
  let {
    contactId,
    subscriptionId,
    body
  } = _ref2;
  return _appsCore.axios.post(`${CRM_ENDPOINT}/contacts/${contactId}/unsubscribe?subscriptionId=${subscriptionId}`, body);
};
exports.optOutContact = optOutContact;
const getContactLeads = async _ref3 => {
  let {
    id,
    showArchived
  } = _ref3;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/leads/findByContactId?contactId=${id}&showArchived=${showArchived}`);
};
exports.getContactLeads = getContactLeads;
const getContactsDuplicates = async _ref4 => {
  let {
    organizationId,
    pageSize,
    pageIndex
  } = _ref4;
  const body = {
    filters: {
      organizationId
    },
    meta: {
      from: pageIndex,
      size: pageSize
    }
  };
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/possibleMerges`, body);
};
exports.getContactsDuplicates = getContactsDuplicates;
const invalidateContactDuplicates = async id => {
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/possibleMerges/${id}/invalidate`, {});
};
exports.invalidateContactDuplicates = invalidateContactDuplicates;
const getContactDocuments = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/contactDocuments?contactId=${id}`);
};
exports.getContactDocuments = getContactDocuments;
const getDocument = async _ref5 => {
  let {
    documentId
  } = _ref5;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/contactDocuments/${documentId}`);
};
exports.getDocument = getDocument;
const downloadDocument = async _ref6 => {
  let {
    documentId
  } = _ref6;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/contactDocuments/${documentId}/download`, {
    responseType: 'blob'
  });
};
exports.downloadDocument = downloadDocument;
const uploadDocuments = async _ref7 => {
  let {
    organizationId,
    contactId,
    form
  } = _ref7;
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/contactDocuments/upload?organizationId=${organizationId}&contactId=${contactId}`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
exports.uploadDocuments = uploadDocuments;
const updateDocumentDesc = async _ref8 => {
  let {
    body
  } = _ref8;
  const documentId = body?.id;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/contactDocuments/${documentId}`, body);
};
exports.updateDocumentDesc = updateDocumentDesc;
const archiveDocument = async _ref9 => {
  let {
    documentId
  } = _ref9;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/contactDocuments/${documentId}/archive`);
};
exports.archiveDocument = archiveDocument;
const getContactSubscriptions = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/contacts/${id}/subscriptions`);
};
exports.getContactSubscriptions = getContactSubscriptions;
const getDownloadTemplate = async () => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/contacts/csv/download`);
};
exports.getDownloadTemplate = getDownloadTemplate;
const uploadContactsFile = async _ref10 => {
  let {
    organizationId,
    organizationUnitId,
    implicitConsent,
    explicitConsent,
    file
  } = _ref10;
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/contacts/csv/upload?organizationId=${organizationId}&organizationUnitId=${organizationUnitId}&implicitConsent=${implicitConsent}&explicitConsent=${explicitConsent}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
exports.uploadContactsFile = uploadContactsFile;
const getVehicleDetails = async id => {
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/clientVehicles/${id}`);
};
exports.getVehicleDetails = getVehicleDetails;
const updateVehicleDetails = async _ref11 => {
  let {
    id,
    body
  } = _ref11;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/clientVehicles/${id}`, body);
};
exports.updateVehicleDetails = updateVehicleDetails;
const createContactTag = async body => {
  return await _appsCore.axios.post(`${CRM_ENDPOINT}/contacts/newContactTag`, body);
};
exports.createContactTag = createContactTag;