"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _deskingError = require("@sm360/desking-error");
var _actions = require("../../redux/actions");
var _selectors = require("../../redux/selectors");
var _CreditApplicationModal = _interopRequireDefault(require("./CreditApplicationModal"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
/**
 * @param {Object} state - Global state
 * @returns {Object} injected props
 */
const mapStateToProps = state => {
  const isContractValidationAvailable = (0, _selectors.getIsContractValidationAvailable)(state);
  return {
    isContractValidationAvailable
  };
};
const mapDispatchToProps = dispatch => ({
  setIsCreditApplicationDecisionRefreshing: isRefreshing => dispatch(_actions.Creators.setIsCreditApplicationDecisionRefreshing(isRefreshing)),
  setIsContractValidationDecisionRefreshing: isRefreshing => dispatch(_actions.Creators.setIsContractValidationDecisionRefreshing(isRefreshing)),
  getCreditApplicationSpecialPrograms: () => dispatch(_actions.Creators.getCreditApplicationSpecialPrograms()),
  addDeleteCreditApplicationRebate: item => dispatch(_actions.Creators.addDeleteItem(item, null, true)),
  getCreditApplicationDecision: () => dispatch(_actions.Creators.getCreditApplicationDecision()),
  getContractValidationDecision: () => dispatch(_actions.Creators.getContractValidationDecision()),
  refreshQuote: () => dispatch(_actions.Creators.refreshQuote())
});
var _default = exports.default = (0, _redux.compose)((0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps), _deskingError.withErrorWrapper)(_CreditApplicationModal.default);