"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unassignCall = exports.invalidateCall = exports.getStaticData = exports.getSMS = exports.getLeads = exports.getEmails = exports.getCalls = exports.fetchSuggestions = exports.extendCallTakenWithConfiguredNumber = exports.dismissSMS = exports.deleteSMS = exports.deleteLead = exports.deleteEmail = exports.associateCall = exports.assignCall = void 0;
var _appsCore = require("@sm360/apps-core");
var _commonsApi = require("@sm360/commons-api");
const CRM_ENDPOINT = process.env.REACT_APP_CRM_API_URL;

// GET NOTIFICATIONS
const getCalls = async _ref => {
  let {
    organizationId,
    organizationUnitIds
  } = _ref;
  const orgUnitIdsQuery = organizationUnitIds?.length > 0 ? `&organizationUnitIds=${organizationUnitIds.join(',')}` : '';
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/notifications/${organizationId}?type=Call${orgUnitIdsQuery}`);
};
exports.getCalls = getCalls;
const getSMS = async _ref2 => {
  let {
    user
  } = _ref2;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/notifications/${user.id}?type=Sms`);
};
exports.getSMS = getSMS;
const getEmails = async _ref3 => {
  let {
    organizationId
  } = _ref3;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/notifications/${organizationId}?type=Email`);
};
exports.getEmails = getEmails;
const getLeads = async _ref4 => {
  let {
    organizationId
  } = _ref4;
  return await _appsCore.axios.get(`${CRM_ENDPOINT}/notifications/${organizationId}?type=Lead`);
};

// DISMISS NOTIFICATIONS
exports.getLeads = getLeads;
const dismissSMS = async _ref5 => {
  let {
    organization,
    id,
    userId
  } = _ref5;
  return await _appsCore.axios.delete(`${CRM_ENDPOINT}/notifications/${organization.id}/dismiss?type=Sms&entityId=${id}&userId=${userId}`);
};

// DELETE NOTIFICATIONS
// Delete SMS notification for all users
exports.dismissSMS = dismissSMS;
const deleteSMS = async _ref6 => {
  let {
    organization,
    id
  } = _ref6;
  return await _appsCore.axios.delete(`${CRM_ENDPOINT}/notifications/${organization.id}?type=Sms&entityId=${id}`);
};

// Delete email notification for all users
exports.deleteSMS = deleteSMS;
const deleteEmail = async _ref7 => {
  let {
    organization,
    id
  } = _ref7;
  return await _appsCore.axios.delete(`${CRM_ENDPOINT}/notifications/${organization.id}?type=Email&entityId=${id}`);
};

// Delete lead notification for all users
exports.deleteEmail = deleteEmail;
const deleteLead = async _ref8 => {
  let {
    organization,
    id
  } = _ref8;
  return await _appsCore.axios.delete(`${CRM_ENDPOINT}/notifications/${organization.id}?type=Lead&entityId=${id}`);
};
exports.deleteLead = deleteLead;
const fetchSuggestions = async _ref9 => {
  let {
    organization,
    number
  } = _ref9;
  const orgUnits = await _commonsApi.OrganizationService.getDealers({
    orgId: organization.id
  });
  const orgUnitsIds = orgUnits.map(orgUnit => orgUnit.id);
  const rawNumber = String(number)?.slice(-10);
  const formattedOrgUnitIds = orgUnitsIds.length ? '&organizationUnitIds='.concat('', orgUnitsIds.join('&organizationUnitIds=')) : '';
  return _appsCore.axios.get(`${CRM_ENDPOINT}/contacts/findSuggestedContactsAndLeads?number=${Number(rawNumber)}&organizationId=${organization.id}${formattedOrgUnitIds}`);
};
exports.fetchSuggestions = fetchSuggestions;
const assignCall = async _ref10 => {
  let {
    id,
    userId,
    organizationId
  } = _ref10;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/activities/${id}/call/assign?userId=${userId}&organizationId=${organizationId}`);
};
exports.assignCall = assignCall;
const unassignCall = async _ref11 => {
  let {
    id,
    userId,
    organizationId
  } = _ref11;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/activities/${id}/call/unassign?userId=${userId}&organizationId=${organizationId}`);
};
exports.unassignCall = unassignCall;
const associateCall = async _ref12 => {
  let {
    id,
    leadId,
    contactId,
    userId,
    organizationId,
    organizationUnitId
  } = _ref12;
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/activities/${id}/call/associateLeadOrContact?userId=${userId}&organizationId=${organizationId}&type=Call&contactId=${contactId}${leadId ? `&leadId=${leadId}` : ''}${organizationUnitId ? `&organizationUnitId=${organizationUnitId}` : ''}`);
};
exports.associateCall = associateCall;
const invalidateCall = async _ref13 => {
  let {
    id,
    organizationId,
    organizationUnitId,
    userId
  } = _ref13;
  const orgUnitIdParam = organizationUnitId ? `&organizationUnitId=${organizationUnitId}` : '';
  return await _appsCore.axios.put(`${CRM_ENDPOINT}/notifications/invalidate?organizationId=${organizationId}&activityId=${id}&userId=${userId}&activityStatusId=7&type=Call${orgUnitIdParam}`);
};
exports.invalidateCall = invalidateCall;
const extendCallTakenWithConfiguredNumber = async _ref14 => {
  let {
    callTaken,
    dispatch,
    organization
  } = _ref14;
  const fullActivity = await _appsCore.axios.get(`${CRM_ENDPOINT}/activities/${callTaken.activityId}`);
  const activityConfiguredNumberId = fullActivity.data?.configuredNumberId;
  if (activityConfiguredNumberId) {
    const configuredNumbers = await _appsCore.axios.get(`${CRM_ENDPOINT}/configuredNumbers/organization/${organization.id}`);
    if (configuredNumbers.data?.length) {
      const configuredNumber = configuredNumbers.data.find(c => c.id === activityConfiguredNumberId);
      if (configuredNumber?.organization_unit_id) {
        dispatch({
          type: 'setCallTaken',
          callTaken: {
            ...callTaken,
            organizationUnitId: configuredNumber.organization_unit_id
          }
        });
      }
    }
  }
};
exports.extendCallTakenWithConfiguredNumber = extendCallTakenWithConfiguredNumber;
const getStaticData = async _ref15 => {
  let {
    dispatch,
    organizationId,
    organizationUnitIds,
    user
  } = _ref15;
  const allPromise = [getEmails({
    organizationId
  })];
  const allPromiseRep = ['emails'];
  if (user.rights.includes('CrmSmsFeatureUse') || user.rights.includes('ReadAllSmsNotifications')) {
    allPromise.push(getSMS({
      user
    }));
    allPromiseRep.push('sms');
  }
  if (user.rights.includes('ReceiveNotificationOnNewLead')) {
    allPromise.push(getLeads({
      organizationId
    }));
    allPromiseRep.push('leads');
  }
  if (user.rights.includes('IvrInbound')) {
    allPromise.push(getCalls({
      organizationId,
      organizationUnitIds
    }));
    allPromiseRep.push('calls');
  }
  Promise.all(allPromise).then(r => {
    const notifications = allPromiseRep.map((t, idx) => {
      return {
        [t]: r[idx].data
      };
    });
    dispatch({
      type: 'initNotifications',
      notifications
    });
  });
};
exports.getStaticData = getStaticData;